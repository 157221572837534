<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { compareAddress } from "@/utils/address.util";

export default {
  computed: {
    ...mapGetters({
      account: "wallet/account",
    }),
    ...mapState({
      projectDetailMap: (state) => state.projects.projectDetailMap,
    }),
    projectDetail() {
      const { id } = this.$route.params;
      console.debug({ id, projectDetailMap: this.projectDetailMap });
      if (id) {
        const data = this.projectDetailMap[id];
        console.debug({ data });
        return data;
      }
      return {};
    },
  },
  created() {
    this.fetchProjectDetailById();
  },
  watch: {
    account(value) {
      const { owner_address } = this.projectDetail;
      if (!compareAddress(owner_address, value)) {
        this.$router.push("/manage/projects");
      }
    },
  },
  methods: {
    ...mapActions({
      fetchProjectDetail: "projects/fetchProjectDetail",
    }),
    fetchProjectDetailById() {
      const { id } = this.$route.params;
      this.fetchProjectDetail(id);
    },
  },
};
</script>

<style></style>
